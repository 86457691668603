import React, { useState, useEffect } from "react"
import {
  PageHeader,
  Input,
  message,
  Button,
  Form,
} from "antd"
import { navigate } from "gatsby"

import { getRandIP } from "../../utils/helper"

import { USERID, USERNAME } from "../../utils/constant"
import Layout from "../../layouts"

import "@ant-design/compatible/assets/index.css"
import "./upload.css"
import { submitJob } from "../../api/job"

const ThemeUpload = () => {
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [form] = Form.useForm()

  async function handleSubmit() {
    if (typeof window !== "undefined") {
      const values = await form.validateFields()
      setValidated(true)
      const publisherName = localStorage.getItem(USERNAME)
      const publisherID = localStorage.getItem(USERID)

      const loginIP = window.localStorage.getItem("ip") ? localStorage.getItem("ip") : getRandIP();
      window.localStorage.setItem("ip", loginIP);
      const res = await submitJob({
        ...values,
        publisherID,
        publisherName,
        loginIP
      })
      if (res && res.data.code === "0000") {
        message.success("发布成功，后台审核通过后即可查看")
        navigate("/jobs")
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (validated) {
      setLoading(true)
    }
  }, [validated])

  const formItemLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
    },
  }

  return (
    <Layout isNavbarDisplay={false} isSpacerDisplay={false} title="发布招聘信息">
      <div className="mdnice-theme-upload">
        <PageHeader title="全部岗位" onBack={() => navigate("/jobs")} />
        <Form
          {...formItemLayout}
          form={form}
          onFinish={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="upload-container">
            <div className="upload-heading">
              <div className="upload-heading-right">
                <Form.Item
                  label="联系方式"
                  name="mobile"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="联系方式" />
                </Form.Item>
                <Form.Item
                  label="公司名称"
                  name="company"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="公司名称" />
                </Form.Item>
                <Form.Item
                  label="岗位名称"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="岗位名称" />
                </Form.Item>
                <Form.Item
                  label="薪资待遇"
                  name="salary"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="薪资待遇" />
                </Form.Item>
                <Form.Item
                  label="招聘地址"
                  name="address"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="招聘地址" />
                </Form.Item>
                <Form.Item
                  label="学历要求"
                  name="education"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="学历要求" />
                </Form.Item>
                <Form.Item
                  label="岗位描述"
                  name="desc"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="岗位描述" />
                </Form.Item>


                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-submit"
                  loading={loading}
                >
                  发布
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  )
}

export default ThemeUpload
